<template>
  <div class="my-10 mevcut-container text-center" data-theme="delivering">
    <div class="text-2xl mb-1 text-nav_color font-bold">
      {{ t('successful_purchase_cart') }}
      <v-icon icon="mdi mdi-check-circle" :size="20" class="text-green mx-1" />
    </div>
    <div class="font-medium text-lg mx-0.5 mb-6 text-center text-black">
      {{
        t('payment_completed_successfully', {
          nameStore: order?.store?.name
        })
      }}
    </div>
    <div v-if="otherStoresInCart?.length" class="mb-2">
      <div class="font-semibold text-lg">
        {{ t('still_have_orders') }}
      </div>
      <div
        v-for="(storeItem, index) in otherStoresInCart"
        :key="`storeItem ${storeItem?.id}`"
        class="flex justify-center items-center text-black"
      >
        <div v-if="otherStoresInCart.length > 1" class="mx-1">
          {{ index + 1 }}-
        </div>
        <div>
          {{ storeItem?.store?.name }}
        </div>
      </div>
      <div class="h-4"></div>
      <MevcutLink
        to="/cart"
        class="bg-nav_color text-text_alt_color rounded-lg p-1"
        :aria-label="t('aria_page_label', { page: t('cart') })"
      >
        {{ t('return_to_cart', { page: t('cart') }) }}
      </MevcutLink>

      <div class="my-4 text-black">
        {{ t('or') }}
      </div>
    </div>
    <div class="flex items-center justify-center">
      <div class="text-black">{{ t('view_bill') }}</div>
      <MevcutLink
        class="flex-shrink-0"
        :aria-label="t('aria_page_label', { page: t('orders') })"
        :to="`/my-account/orders/${route.query.bill_id}`"
      >
        <div class="text-nav_color mx-2 font-medium">
          {{ t('click_here') }}
        </div>
      </MevcutLink>
    </div>

    <div v-if="nearByStores?.length" class="mt-10">
      <div class="text-xl text-nav_color font-medium">
        {{ t('free_delivery') }}
      </div>
      <MevcutSwiper :items="nearByStores" auto-slides>
        <template #slide="{ item }">
          <div class="sm:px-1">
            <StoresCard
              class="transition-transform duration-300 transform hover:scale-105 mt-3"
              :store="(item as Store)"
            />
          </div>
        </template>
      </MevcutSwiper>
    </div>
  </div>
</template>

<script setup lang="ts">
import getAllRoutes from '~/composables/AppApiRoutes'
import type { Store } from '~~/composables/useMenuModel'
const { url } = useDomainHost()
const { t } = useI18n()
const route = useRoute()
const { fetchBill } = useBill()
const { storeModel } = useMenuModel()
const cart = useCartStore()
const order = ref<UserOrder | null>(null)

const nearByStores = ref<Store[] | null>(null)
if (route.query.bill_id) {
  const { data: orderTemp } = await fetchBill(route.query.bill_id.toString())

  if (orderTemp.value) {
    order.value = orderTemp.value!
  }

  await fetchNearByStores(route.query.bill_id.toString())
}

const otherStoresInCart = computed(() => {
  if (order.value?.store_id) {
    return cart.itemsByStoreSortedByOffer.filter(
      (itemStore: ItemStoreCart) => itemStore.id !== order.value?.store_id
    )
  }
  return cart.itemsByStoreSortedByOffer
})

async function fetchNearByStores(billId?: string) {
  const { data } = await useBasicFetch(
    getAllRoutes().storesRoutes.getNearbyStoresByServer,
    {
      query: {
        billId
      }
    }
  )

  if (data.value?.data?.stores?.length) {
    nearByStores.value = data.value?.data?.stores?.map(storeModel)
  }
}
useServerSeoMeta(
  useOgMeta(
    url + '/successful-purchase-cart',
    t('successful_purchase_cart'),
    t('mevcut_description'),
    url + '/images/mevcut.webp'
  )
)

useHead({
  title: t('successful_purchase_cart'),
  link: [useSeoCanonical(url + '/successful-purchase-cart')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(
        2,
        t('successful_purchase_cart'),
        url + '/successful-purchase-cart'
      )
    ])
  ]
})
</script>
